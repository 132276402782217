import { api } from '../../config/base-api';

import type {
  GetSubscriptionRenewPlansParams,
  GetSubscriptionRenewPlansResponse,
} from './types';

export async function getSubscriptionRenewPlans(
  { subscriptionId, business, sessionId }: GetSubscriptionRenewPlansParams,
  isServer?: boolean
): Promise<GetSubscriptionRenewPlansResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/subscription/${subscriptionId}/renew`
    : `/api/v1/subscription/${subscriptionId}/renew`;

  const res = await api.get<GetSubscriptionRenewPlansResponse>(requestUrl, {
    params: {
      sessionId,
      business,
    },
  });

  return res.data;
}
