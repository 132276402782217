import axios from 'axios';

import projectConfig from '../../../package.json';

type Config = {
  headers?: Record<string, string>;
  baseUrl?: string;
};

const SIXTY_SECONDS_IN_MILLISECONDS = 60000;

function baseApiFactory(config?: Config) {
  const api = axios.create({
    baseURL: config?.baseUrl ?? '/',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      appVersion: projectConfig.version,
      ...(config?.headers && config?.headers),
    },
    timeout: SIXTY_SECONDS_IN_MILLISECONDS,
    validateStatus: (status) => status >= 200 && status < 300,
  });

  return api;
}

const api = baseApiFactory();
export { api };
