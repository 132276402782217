import { getRawQuery } from 'utils/globalFunctions';
import { ProductsIds, ProductsCodes, PageProps } from './generalTypes';
import { Business } from 'types/Business';

export const PRODUCTS_AVAILABLE_TO_GET_CONFIG = [
  ProductsIds.VENDE_C,
  ProductsIds.EU_MILITAR,
  ProductsIds.PERFORMAN_C,
  ProductsIds.C_HUB,
  ProductsIds.WSP,
  ProductsIds.MEUSUCESSO,
  ProductsIds.POWERHOUSE,
  ProductsIds.APROVATOTAL,
  ProductsIds.APROVATOTAL_ESQUADRAO_ELITE,
  ProductsIds.WISEUP_ONLINE,
  ProductsIds.WISEUP,
  ProductsIds.NUMBER_ONE,
  ProductsIds.TEENS,
  ProductsIds.WISERGV,
  ProductsIds.VENDEC_WISERGV,
  ProductsIds.MEDCOF,
  ProductsIds.WISEUP_LIVE,
  ProductsIds.LIBERTE_C,
  ProductsIds.RESOLVEENEM,
  ProductsIds.ESCOLACONQUER,
];

export const CHECKOUT_CONFIG_BLACKLIST_PAGES = [
  '/',
  '/readiness',
  '/healthcheck',
  '/generic-error',
];

export const SELLER_BLACKLIST_PAGES = [
  '/',
  '/readiness',
  '/healthcheck',
  '/generic-error',
];

/**
 * Não foi possiveil utilizar o tema para armazenar essa informação
 * pois nesse nível do arquivo o tema ainda não foi setado. Sendo assim, o objetivo abaixo
 * precisa ser atualizado com a inserção de novos produtos
 */
export const DEFAULT_BUSINESS_BY_PRODUCT = {
  wup: Business.WISEUP_ONLINE,
  live: Business.WISEUP_LIVE,
  exec: Business.WISEUP,
  numberone: Business.WISEUP,
  teens: Business.WISEUP,
  ms: Business.MEUSUCESSO,
  powerhouse: Business.POWERHOUSE,
  vc: Business.VENDE_C,
  'c-hub': Business.VENDE_C,
  performanc: Business.PERFORMAN_C,
  em: Business.EUMILITAR,
  wsp: Business.WISER,
  aprovatotal: Business.APROVATOTAL,
  'aprovatotal-esquadrao-elite': Business.APROVATOTAL,
  wisergv: Business.POWERHOUSE,
  'vendec-wisergv': Business.VENDE_C,
  medcof: Business.MEDCOF,
  'liberte-c': Business.VENDE_C,
  resolveenem: Business.APROVATOTAL,
  escolaconquer: Business.ESCOLACONQUER,
};

export const DEFAULT_PRODUCT_CODE_BY_PRODUCT = {
  wup: ProductsCodes.WISEUP_ONLINE,
  live: ProductsCodes.WISEUP_LIVE,
  exec: ProductsCodes.WISEUP,
  numberone: ProductsCodes.NUMBER_ONE,
  teens: ProductsCodes.TEENS,
  ms: ProductsCodes.MEUSUCESSO,
  em: ProductsCodes.EU_MILITAR,
  powerhouse: ProductsCodes.POWERHOUSE_EVENT,
  vc: ProductsCodes.VENDE_C,
  performanc: ProductsCodes.PERFORMAN_C,
  wsp: ProductsCodes.WSP,
  aprovatotal: ProductsCodes.APROVATOTAL_PLATAFORMA,
  'aprovatotal-esquadrao-elite': ProductsCodes.APROVATOTAL_ESQUADRAO_ELITE,
  wisergv: ProductsCodes.WISERGV,
  'vendec-wisergv': ProductsCodes.VENDEC_WISERGV,
  medcof: ProductsCodes.MEDCOF_REVALIDA_2024_40,
  resolveenem: ProductsCodes.RESOLVEENEM,
  escolaconquer: ProductsCodes.ESCOLACONQUER,
};

const EUMILITAR_PRODUCT_CODES_WITHOUT_SUFIX = [
  'blackfriday',
  'eear',
  'ema',
  'emfa',
  'emfax',
  'emmq',
  'empa',
  'esa-6-months',
  'esa',
  'esaeear',
  'espcex',
];
export const formatProductSlugToProductCode = ({
  business,
  productSlug,
  product,
}) => {
  if (business === Business.WISEUP && product !== ProductsIds.NUMBER_ONE) {
    return {
      business,
      productCode: productSlug.split('-')[1],
    };
  }

  /**
   * Implementado para enderecar possiveis landing pages que não possuem o prefixo
   * `eumilitar` no código do produto
   */
  if (
    business === Business.EUMILITAR &&
    EUMILITAR_PRODUCT_CODES_WITHOUT_SUFIX.includes(productSlug)
  ) {
    return {
      business,
      productCode: `eumilitar-${productSlug}`,
    };
  }

  return {
    business,
    productCode: productSlug,
  };
};

enum FlowIdMapperByUrlQueryParam {
  MSJANA = 'extended',
  BANKSLIP = 'bankslip',
  PIX = 'pix',
  LIVELO = 'livelo',
}

enum OldUrlQueryParams {
  MSJANA = 'msjana',
  PIX = 'pix',
  LIVELO = 'livelo',
  LIVELO_ACCRUAL = 'livelo-accrual',
  BANKSLIP = 'bankslip',
}

/**
 * Tratativas para tranformar links antigos para o formato utilizando o flowId
 */
export const convertUrlToFlowId = (pageProps: PageProps) => {
  let updatedRawQuery = pageProps?.rawQuery;

  const updateUrlSearchQuery = ({ flowId }) => {
    pageProps.query.flowId = flowId;
    return getRawQuery(pageProps);
  };

  const convertByProduct = {
    MS: () => {
      const isJanaExtendedFlow =
        pageProps?.query?.voucher === OldUrlQueryParams.MSJANA;

      if (isJanaExtendedFlow) {
        return updateUrlSearchQuery({
          flowId: FlowIdMapperByUrlQueryParam.MSJANA,
        });
      }
    },
    WUP: () => {
      const hasLiveloRoute =
        pageProps?.query?.route === OldUrlQueryParams.LIVELO ||
        pageProps?.query?.route === OldUrlQueryParams.LIVELO_ACCRUAL;

      if (hasLiveloRoute) {
        return updateUrlSearchQuery({
          flowId: FlowIdMapperByUrlQueryParam.LIVELO,
        });
      }
    },
  };

  const isOnlyPixFlow = pageProps?.query?.route === OldUrlQueryParams.PIX;

  if (isOnlyPixFlow) {
    return updateUrlSearchQuery({
      flowId: FlowIdMapperByUrlQueryParam.PIX,
    });
  }

  const isOnlyBankslipFlow =
    pageProps?.query?.route === OldUrlQueryParams.BANKSLIP;

  if (isOnlyBankslipFlow) {
    return updateUrlSearchQuery({
      flowId: FlowIdMapperByUrlQueryParam.BANKSLIP,
    });
  }

  if (convertByProduct[pageProps?.product]) {
    const formattedRawQuery = convertByProduct[pageProps?.product]();

    if (formattedRawQuery) {
      updatedRawQuery = formattedRawQuery;
    }
  }

  return updatedRawQuery;
};
