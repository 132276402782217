import { useEffect, useState, createContext } from 'react';

// hooks
import { useProductTheme } from 'hooks/useProductTheme';
import useSessionStorage from 'hooks/useSessionStorage';

// types
import { ProductFlowProps } from 'utils/productFlow';
import type { CartProviderProps } from './CartContextTypes';
import type { AppContextProps, ProductThemeProps } from 'hooks/useCart';

// constants
import { DATA_FORM } from '../constants';
import { useRouterQuery } from 'hooks/useRouterQuery';
import { Business } from 'types';

const CartContext = createContext({} as AppContextProps);

export function CartProvider({ children, pageProps }: CartProviderProps) {
  const routerQuery = useRouterQuery();

  const pagePropsProductSlug = pageProps.productFlow?.query?.['product-slug'];

  const theme = useProductTheme({
    product: pageProps.product,
    area: pageProps.area,
    productSlug:
      pagePropsProductSlug ||
      routerQuery['product-slug'] ||
      pageProps.productCode,
  });

  const _ga = pageProps._ga;
  const product = pageProps.product;

  const rawQuery = pageProps.rawQuery;
  const environment = pageProps.environment;
  const area = pageProps.area;
  const allCheckoutFlowsByProductCode = pageProps.allCheckoutFlowsByProductCode;
  const productCode = pageProps.productCode || theme?.data?.productCode;
  const productBusiness = (pageProps.business ||
    theme?.data?.business) as Business;
  const isMozambiqueTheme = pageProps.isMozambiqueTheme || false;
  const isLiveEnabled = pageProps.isLiveEnabled || false;
  const [dsBrand, setDsBrand] = useState(pageProps.dsBrand);
  const [dsMode, setDsMode] = useState(pageProps.dsMode);
  /**
   * mainProductTheme vai guardar o productTheme do product principal.
   */
  const [mainProductTheme, setMainProductTheme] =
    useState<ProductThemeProps | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [productFlow, setProductFlow] = useState<ProductFlowProps | null>(
    pageProps.productFlow
  );
  const [userPhone, setUserPhone] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userLeadId, setUserLeadId] = useState<string | null>(null);
  const [purchaseToken, setPurchaseToken] = useState<string | null>(null);
  const [productTheme, setProductTheme] = useState(theme);
  const [redirectSuccess, setRedirectSuccess] = useState('/login');
  const [sellerName, setSellerName] = useState(pageProps.sellerName || null);
  const [sellerAccount] = useState(pageProps.sellerAccount || null);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [dataForm] = useSessionStorage(DATA_FORM, null);
  const [shouldUseUpsellTheme] = useSessionStorage('shouldUseUpsellTheme');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setUserEmail(dataForm?.email);
    setUserName(dataForm?.name);
    setUserPhone(dataForm?.phone);
  }, [dataForm]);

  useEffect(() => {
    if (!shouldUseUpsellTheme) {
      setProductTheme(theme);
    }
    setMainProductTheme(theme);
  }, [theme, shouldUseUpsellTheme]);

  return (
    isMounted && (
      <CartContext.Provider
        value={{
          userName,
          userPhone,
          userEmail,
          userLeadId,
          purchaseToken,
          _ga,
          productTheme,
          redirectSuccess,
          product,
          isLiveEnabled,
          sellerName,
          rawQuery,
          environment,
          area,
          loading,
          isMozambiqueTheme,
          mainProductTheme,
          productFlow,
          productBusiness,
          productCode,
          sellerAccount,
          dsMode,
          dsBrand,
          allCheckoutFlowsByProductCode,
          setDsBrand,
          setDsMode,
          setLoading,
          setSellerName,
          setRedirectSuccess,
          setProductTheme,
          setUserName,
          setUserPhone,
          setUserEmail,
          setUserLeadId,
          setPurchaseToken,
          setProductFlow,
        }}
      >
        {children}
      </CartContext.Provider>
    )
  );
}

export default CartContext;
