/**
 * @file devemos declarar aqui as chaves de objetos e dados armazenados na session-storage.
 * Usar sempre maiusculo, tanto para o nome de constantes, quanto para os valores.
 * Valores fora do padrão para manter a compatibilidade com valores já sendo usados
 * pela aplicação.
 */

export const DATA_LEAD = 'dataLead';
export const DATA_FORM = 'dataForm';
export const DEFAULT_SELECTED_PLAN = 'defaultSelectedPlan';
export const IS_PAYMENT_IN_PERSON = 'isPaymentInPerson';
export const PURCHASED_PROFILES_AMOUNT = 'PURCHASED_PROFILES_AMOUNT';
export const PURCHASED_PLAN = 'PURCHASED_PLAN';
