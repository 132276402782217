export enum ProductsIds {
  WISEUP_ONLINE = 'WUP',
  WISEUP = 'EXEC',
  NUMBER_ONE = 'NUMBERONE',
  TEENS = 'TEENS',
  WISEUP_LIVE = 'LIVE',
  MEUSUCESSO = 'MS',
  POWERHOUSE = 'POWERHOUSE',
  EU_MILITAR = 'EM',
  VENDE_C = 'VC',
  PERFORMAN_C = 'PERFORMANC',
  C_HUB = 'C-HUB',
  WSP = 'WSP',
  APROVATOTAL = 'APROVATOTAL',
  APROVATOTAL_ESQUADRAO_ELITE = 'APROVATOTAL-ESQUADRAO-ELITE',
  WISERGV = 'WISERGV',
  VENDEC_WISERGV = 'VENDEC-WISERGV',
  MEDCOF = 'MEDCOF',
  LIBERTE_C = 'LIBERTE-C',
  RESOLVEENEM = 'RESOLVEENEM',
  ESCOLACONQUER = 'ESCOLACONQUER',
}

/** @deprecated */
export enum ProductsCodes {
  WISEUP_ONLINE = 'wol',
  WISEUP_LIVE = 'live',
  WISEUP = 'exec',
  NUMBER_ONE = 'n1',
  TEENS = 'teens',
  MEUSUCESSO = 'ms',
  POWERHOUSE_EVENT = 'PWH-EVENTO',
  VENDE_C = 'vende-c',
  PERFORMAN_C = 'performan-c',
  C_HUB = 'c-hub',
  WSP = 'wsp-conference-2023',
  EU_MILITAR = 'eumilitar-emfa',
  APROVATOTAL = 'aprovatotal',
  APROVATOTAL_ESQUADRAO_ELITE = 'aprovatotal-esquadrao-elite',
  APROVATOTAL_PLATAFORMA = 'aprovatotal-plataforma',
  WISERGV = 'wisergv',
  VENDEC_WISERGV = 'vendec-wisergv',
  MEDCOF_REVALIDA_2024_40 = 'medcof-revalida-2024-40',
  RESOLVEENEM = 'aprovatotal-resolve-enem',
  ESCOLACONQUER = 'cplus',
}

interface ColorShade {
  dark: string;
  light: string;
  medium: string;
  pure: string;
}

interface ColorPalette {
  highContrast: string;
  lowContrast: string;
  mediumContrast: string;
  pure: string;
  button?: string;
}

export interface ThemeColor {
  primary: ColorShade;
  secondary: ColorShade;
  neutral: ColorPalette;
  feedback: {
    success: ColorPalette;
    alert: ColorPalette;
    error: ColorPalette;
    button: string;
  };
}

export interface PageProps {
  query: Record<string, unknown>;
  rawQuery: string;
  product: ProductsIds;
}
