import { api } from '../config/base-api';

export interface CreateLeadParams {
  name: string;
  email: string;
  phone: string;
  operation: string;
  source: string;
  seller: string;
  voucher: string;
  planId?: string;
  /**
   * A ideia futura é removermos o plan, planType, business e productCode,
   * deixando apenas o planId
   */
  plan?: string;
  planType?: string;
  captchaToken: string;
  analytics: {
    gclid: string;
  };
  business?: string;
  productCode?: string;
  product?: string;
  route?: string;
  cpf?: string;
  metadata?: {
    GAClientId?: string;
    gclid?: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    utmId?: string;
    utmCreativeFormat?: string;
    utmMarketingTactic?: string;
    utmSourcePlatform?: string;
  };
}

export interface CreateLeadResponse {
  sfLeadId: string;
  leadId: string;
  route: string;
  isOrderTracked: boolean;
  powerHousePlansEnabledToPurchase: string[];
  voucher?: string;
  plan?: string;
  planType?: string;
}

export async function createLead(
  data: CreateLeadParams,
  isServer?: boolean
): Promise<CreateLeadResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/lead`
    : `/api/v1/lead`;

  const res = await api.post(requestUrl, data);

  return res.data;
}
