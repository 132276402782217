import { api } from '../../config/base-api';

export interface GetProductDataByPlanIdResponse {
  id: string;
  code: string;
}

export async function getProductDataByPlanId(
  planId: string,
  isServer: boolean
): Promise<GetProductDataByPlanIdResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v1/plan/${planId}/product`
    : `/api/v1/plan/${planId}/product`;

  const res = await api.get<GetProductDataByPlanIdResponse>(requestUrl);

  return res.data;
}
