import * as Sentry from '@sentry/nextjs';
import { InternalLogger, ErrorLevel, AcceptableTags } from '../types';
import { AxiosError } from 'axios';
import { CUSTOM_URL_FINGERPRINT_HEADER } from 'constants/Http';

export class SentryAdapter implements InternalLogger {
  constructor(private readonly logger = Sentry) {}

  private setCustomFingerprintForHttpCalls(
    scope: Sentry.Scope,
    httpData: {
      requestUrl: string;
      customUrlFingerprint?: string;
      requestMethod: string;
      statusCode: string;
    }
  ): void {
    const formattedRequestUrl = httpData.requestUrl.split('?');
    const requestUrlWithoutQueryStr = formattedRequestUrl[0];
    const queryString = formattedRequestUrl[1];

    const requestUrlToGroupBy =
      httpData.customUrlFingerprint || requestUrlWithoutQueryStr;

    if (requestUrlWithoutQueryStr) {
      scope.setFingerprint([
        requestUrlToGroupBy,
        httpData.requestMethod,
        String(httpData.statusCode),
      ]);
    }
    if (queryString) {
      scope.setExtra('queryString', queryString);
    }
  }

  captureError(
    errorLevel: ErrorLevel,
    error: Error | string | AxiosError,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    const customUrlFingerprint: string | undefined = (error as AxiosError)
      .config?.headers?.[CUSTOM_URL_FINGERPRINT_HEADER];
    const url = (error as AxiosError).config?.url;
    const method = (error as AxiosError).config?.method;
    const statusCode = (error as AxiosError).response?.status;

    this.logger.withScope((scope) => {
      if (additionalInfo) {
        if (url && statusCode && method) {
          this.setCustomFingerprintForHttpCalls(scope, {
            requestUrl: url,
            customUrlFingerprint,
            requestMethod: method,
            statusCode: String(statusCode),
          });
        }

        scope.setContext('Additional Info', additionalInfo);
      }

      if (additionalInfo.userEmail) {
        scope.setUser({ email: additionalInfo.userEmail as string });
      }

      if (additionalTags) {
        scope.setTags(additionalTags);
      }

      scope.setExtra('errorLevel', errorLevel);
      scope.setTag('errorLevel', errorLevel);
      scope.setLevel(errorLevel);

      scope.setExtra('isFromHandler', true);

      this.logger.captureException(error);
    });
  }
}
